<template>
  <div
    class="
      h-screen
      flex
      w-full
      bg-img
      vx-row
      no-gutter
      items-center
      justify-center
    "
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <div class="vx-row no-gutter justify-center items-center">
        <div class="vx-col hidden lg:block">
          <center>
            <img src="@/assets/logo.png" alt="login" class="mx-auto lg:w-1/4" />
          </center>
        </div>

        <div class="vx-col mx-auto lg:w-2/3">
          <div class="p-8 login-tabs-container">
            <div class="mb-4">
              <h1 class="text-4xl montserratfont">FORGOTTEN PASSWORD<br /><br /></h1>
              <p>
                PLEASE USE THE FORM BELOW TO SEND PASSWORD RESET INSTRUCTIONS TO
                YOUR EMAIL.
              </p>
            </div>

            <div>
              <md-field>
                <label>Email</label>
                <md-input v-model="email"></md-input>
              </md-field>

              <div class="flex flex-wrap justify-between my-5"></div>
              <md-button
                @click="loginUser()"
                class="float-right oeno-button"
                :disabled="resettingpass"
                >{{
                  resettingpass
                    ? "SNDING PASSWORD LINK"
                    : "SEND RESET PASSWORD DETAILS"
                }}</md-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { HTTP } from "@/axios.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      resettingpass: false,
    };
  },
  methods: {
    ...mapActions(["updateUserInfo", "updateUserToken"]),
    loginUser() {
      // let login now

      this.resettingpass = true;
      HTTP.post("/api/forgotPassword", { email: this.email })
        .then((response) => {
          this.resettingpass = false;

          if (response.data.statuscode == 200) {
            this.$router.push("/forgotreset");
          } else {
            this.$notify({
              group: "oeno",
              title: "Password Reset Error",
              text: "Sorry there seems to have been an error processing your Password Reset, please try again later.",
              type: "error",
            });
          }
        })
        .catch(() => {
          this.resettingpass = false;

          this.$notify({
            group: "oeno",
            title: "Password Reset Error",
            text: "Sorry there seems to have been an error processing your Password Reset, please try again later.",
            type: "error",
          });

        });
    },
  },
};
</script>
  
  <style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
</style>
  